/** @jsxRuntime classic */
/** @jsx jsx */

import { CSSProperties } from "@emotion/serialize";
import { gql } from "@keystone-6/core";
import {
  component,
  fields,
} from "@keystone-6/fields-document/component-blocks";
import { jsx } from "@keystone-ui/core";

export const componentBlocks = {
  link: component({
    label: "Internal Link",
    props: {
      text: fields.child({ kind: "inline", placeholder: "Text..." }),
      link: fields.relationship({
        label: "Page Link",
        listKey: "Page",
        many: false,
        selection: gql`id slug`,
      }),
    },
    component: ({ text, link }) => (
      <a href={link.value?.data?.slug || ""}>{text || ""}</a>
    ),
  }),
  hero: component({
    label: "Hero",
    props: {
      heading: fields.child({ kind: "inline", placeholder: "Heading..." }),
      message: fields.child({ kind: "inline", placeholder: "Message..." }),
      image: fields.url({ label: "Image URL" }),
      cta: fields.conditional(
        fields.checkbox({ label: "add CTA", defaultValue: false }),
        {
          true: fields.object({
            url: fields.url({ label: "URL" }),
            label: fields.child({ kind: "inline", placeholder: "CTA..." }),
          }),
          false: fields.empty(),
        }
      ),
    },
    component: ({ heading, message, cta, image }) => {
      const style: CSSProperties = {
        width: "100%",
        height: 420,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 8,
        color: "white",
      };
      const imgStyle: CSSProperties = image.value
        ? {
            background: `url('${image.value}') rgba(0, 0, 0, 0.46)`,
            backgroundBlendMode: "overlay",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }
        : {};

      const buttonStyle = {
        pointer: "cursor",
        backgroundColor: "rgb(120, 0, 8)",
        color: "white",
        padding: `12px 16px`,
        borderRadius: "32px",
        textDecoration: "none",
      };

      return (
        <div css={{ ...style, ...imgStyle }}>
          <h1>{heading}</h1>
          {message && <p css={{ marginBottom: 32 }}>{message}</p>}
          {cta.discriminant && (
            <a css={buttonStyle} href={cta.value.url.value}>
              {cta.value.label}
            </a>
          )}
        </div>
      );
    },
  }),
};
