import Layout from "@/components/layout";
import { useTestPageQuery } from "@/generated";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import { InferRenderersForComponentBlocks } from "@keystone-6/fields-document/component-blocks";
import { PageProps } from "gatsby";
import React from "react";
import { componentBlocks } from "../../../keystone/system/views/documentField";

type TestPageProps = PageProps;

const components: InferRenderersForComponentBlocks<typeof componentBlocks> = {
  hero: (props) =>
    componentBlocks.hero.component({
      ...props,
      cta: {
        ...props.cta,
        value: {
          ...props.cta.value,
          url: { value: props.cta.value?.url },
        },
      },
      image: { value: props.image },
    }),
  link: (props) => componentBlocks.link.component(props),
};

const TestPage: React.FC<TestPageProps> = () => {
  const { data } = useTestPageQuery();
  if (!data) return null;

  const { document } = { ...data?.page?.content };

  console.log(document);

  // console.log(document);

  return <Layout>{document && <DocumentRenderer document={document} componentBlocks={components} />}</Layout>;
};

export default TestPage;
